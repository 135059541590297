










































import { Component, Mixins, Ref } from "vue-property-decorator";
import { State } from "vuex-class";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AiReportMixin from "@/mixins/aireport/aiReportMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { VnPlan } from "#/model/vnplan";
import { COMMON } from "#/const";
import UtilMixin from "@/mixins/utilMixin";
import AiGenerateButton from "@/components/patient/common/aireport/AiGenerateButton.vue";
import AiGenerateHistoryDialog from "@/components/patient/common/aireport/AiGenerateHistoryDialog.vue";
import * as CONST from "@/components/patient/common/aireport/const";
import * as report from "@/store/modules/patient/types";

@Component({
  components: {
    PatientHeader,
    BaseReportList,
    AiGenerateButton,
    AiGenerateHistoryDialog
  }
})
export default class PatientPlanList extends Mixins(
  AiReportMixin,
  AxiosMixin,
  FocusingMixin,
  UtilMixin
) {
  @State(report.name) patientState!: report.PatientState;

  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  @Ref("historyDialog")
  private readonly historyDialog?: AiGenerateHistoryDialog;

  private patientId = 0; //利用者ID
  private items: VnPlan[] = []; //看護計画書
  private paths = []; //最新の3つのid

  //看護計画書
  private commentHeader = {
    text: "コメント",
    value: "remand.comment"
  };

  /** getter */

  /** テーブルヘッダー */
  private get Headers(): DataTableHeader[] {
    const h: DataTableHeader[] = [
      {
        text: "計画月",
        value: "yearmonth_wareki",
        align: "start",
        width: "120px",
        sort: SortWarekiDate
      },
      {
        text: "担当者",
        value: "staff1.ibow_nickname",
        align: "start",
        width: "120px"
      },
      {
        text: "記載日",
        value: "write_date_wareki",
        align: "start",
        width: "152px",
        sort: SortWarekiDate
      }
    ];

    if (this.IsAiEnabled) {
      h.push({
        text: "AI自動作成",
        value: "ai_generated_at_wareki",
        align: "start",
        width: "240px",
        sort: SortWarekiDate
      });
    }

    return h;
  }

  /** AI自動作成機能ON/OFF */
  private get IsAiEnabled(): boolean {
    return this.patientState.master.is_ai_vnplan_enabled === 1;
  }

  public created() {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchVnPlan({ tab: 0, page: 0 });
    }
  }

  mounted() {
    // リアルタイムアップデート用リスナーを作成
    this.createAiReportListener(
      this.patientId,
      CONST.AI_GENERATE_REPORT_TYPE_VNPLAN,
      this.fetchVnPlan
    );
  }

  //新規作成
  private clickNew() {
    this.$router.push({
      name: "PatientPlanEdit",
      params: { id: String(this.patientId), vnplanid: "0" }
    });
  }
  //編集
  private clickEdit(item: VnPlan) {
    this.$router.push({
      name: "PatientPlanEdit",
      params: { id: String(this.patientId), vnplanid: String(item.id) }
    });
  }

  //前3回の記録
  private last3TimesVnPlan() {
    this.makePdf(window.base_url + "/api/reportlist/pdf/join", {
      str_pdfs: this.paths
    });
  }

  //看護計画書情報一覧取得
  private fetchVnPlan(obj: { tab: number; page: number }): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplans/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.items) {
          this.paths = res.data.paths;
          this.reportList?.finishLoaded(res.data.items);
          if (obj.page != 0) {
            this.items = this.items.concat(res.data.items);
          } else {
            this.items = res.data.items;
          }
        }
      }
    );
  }

  //差し戻し
  private setRemand(recordItem: { item: VnPlan; comment: string }): void {
    recordItem.item.status = this.REPORT_REMAND;
    recordItem.item.remand.comment = recordItem.comment;
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/saveRemand",
      {
        vn_plan: recordItem.item
      },
      () => {
        this.items = this.items.filter(v => v.id !== recordItem.item.id);
      }
    );
  }

  //PDF
  private pdf(item: VnPlan): void {
    this.makePdf(window.base_url + "/api/patient/vnplan/preview/ids", {
      patient_id: this.patientId,
      vn_plan_ids: [item.id],
      vn_plans: [item]
    });
  }

  /** AI自動作成 */
  private async aiGenerate() {
    if (
      !(await this.$openConfirm(
        "AIが現在有効な利用者情報から看護計画書を\n自動作成します。よろしいですか？"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/aigenerate-pre",
      { patient_id: this.patientId },
      async res => {
        this.postJsonBackgroundIgnore(
          window.base_url + "/api/patient/vnplan/aigenerate",
          { document: res.data.document },
          () => {
            //
          }
        );
        this.$openAlert(
          "作成を開始しました。AI自動作成の結果はチャットで通知します。また、[AI自動作成]ボタンの[履歴をみる]でも状況を確認できます。"
        );
      }
    );
  }

  /** AI自動作成履歴をみる */
  private openHistory() {
    this.historyDialog?.open();
  }
}
