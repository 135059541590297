import Base from "../base";

export interface RelationInstitution extends Base {
  id: number;
  office_div: number;
  parent_relation_institution_id: number;
  office_code: string;
  support_office_div: number;
  name: string;
  furigana: string;
  document_form: number;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  fax_area: string;
  fax_city: string;
  fax_num: string;
  /** 送付状の住所が登録先住所と異なる */
  use_letter_address: number;
  /** 送付状の宛名 */
  letter_name: string;
  /** 送付状の宛名ふりがな */
  letter_furigana: string;
  /** 送付状郵便番号 */
  letter_post_code: string;
  /** 送付状都道府県 */
  letter_pref: number;
  /** 送付状市区町村・町域 */
  letter_city: string;
  /** 送付状番地 */
  letter_area: string;
  /** 送付状建物名 */
  letter_building: string;
  relation_institution_offices?: RelationInstitutionOffice[];
  personnels: Personnel[];
}

export const DefaultRelationInstitution = (): RelationInstitution => ({
  id: 0,
  office_div: 0,
  parent_relation_institution_id: 0,
  office_code: "",
  support_office_div: 0,
  name: "",
  furigana: "",
  document_form: 0,
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  fax_area: "",
  fax_city: "",
  fax_num: "",
  use_letter_address: 0,
  letter_name: "",
  letter_furigana: "",
  letter_post_code: "",
  letter_pref: 0,
  letter_city: "",
  letter_area: "",
  letter_building: "",
  relation_institution_offices: [],
  personnels: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface Personnel extends Base {
  id: number;
  relation_institution_id: number;
  is_empty: number;
  section_name: string;
  full_name: string;
  furigana: string;
  last_name: string;
  last_name_kana: string;
  first_name: string;
  first_name_kana: string;
  /** ステータス\n0:空データ\n1:在職\n2:休職\n3:退職 */
  status: number;
  remarks: string;
  relation_institution_name: string;
  personnel_contacts: PersonnelContact[];
}

export const DefaultPersonnel = (): Personnel => ({
  id: 0,
  relation_institution_id: 0,
  is_empty: 1,
  full_name: "",
  furigana: "",
  last_name: "",
  last_name_kana: "",
  first_name: "",
  first_name_kana: "",
  section_name: "",
  status: 1,
  remarks: "",
  relation_institution_name: "",
  personnel_contacts: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface PersonnelContact {
  id: number;
  personnel_id: number;
  contact_type: number;
  contact_info: string;
}

export const DefaultPersonnelContact = (): PersonnelContact => ({
  id: 0,
  personnel_id: 0,
  contact_type: 0,
  contact_info: ""
});

export interface RelationInstitutionOffice {
  id: number;
  relation_institution_id: number;
  office_id: number;
}
