















































































import Component from "vue-class-component";
import { Prop, Emit, Watch, Mixins } from "vue-property-decorator";
import AppAutocomplete from "../AppAutocomplete.vue";
import AppChatStaffSelector from "./AppChatStaffSelector.vue";
import AppChatSystemIcon from "./AppChatSystemIcon.vue";
import { Choice } from "../../types";
import AxiosMixin from "@/mixins/axiosMixin";
import { RoomType, FileType } from "./const";
import UtilMixin from "@/mixins/utilMixin";
import { Account } from "./AppChatBase.vue"

interface Staff {
  id: number;
  ewell_user_id: number;
  nickname: string;
  div_name: string;
  ibow_family_name: string;
  ibow_first_name: string;
  furigana: string;
  office_name: string;
}

interface SelectedStaff {
  id: number;
  name: string;
  ewellUserId: number;
}

@Component({
  components: {
    AppAutocomplete,
    AppChatStaffSelector,
    AppChatSystemIcon,
  },
})
export default class AppChatRoomEditor extends Mixins(AxiosMixin, UtilMixin) {
  @Prop() viewType!: number;
  @Prop({
    default: () => {
      return [];
    },
  })
  agreements!: Choice[];
  @Prop() iconPath?: string;
  @Prop({
    default: () => {
      return [];
    },
  })
  currentAccounts!: Account[];
  @Prop() agreementId!: number | null;
  @Prop() talkTitle!: string;
  @Prop() roomType!: number;
  @Prop({ default: undefined }) fileType!: number | undefined;
  @Prop() selfNickname!: string;
  @Prop() selfEwellUserId!: number;
  @Prop() selfStaffId!: number;

  private ViewType = {
    create: 0,
    edit: 1,
  } as const;

  private staffs: {
    id: number;
    ewellUserId: number;
    staffName: string;
    officeName: string;
  }[] = [];
  private innerTalkTitle = "";
  private innerAgreementId: number | null = null;
  private selectedStaffList: SelectedStaff[] = [];
  private addedStaffs: SelectedStaff[] = [];
  private removedStaffs: SelectedStaff[] = [];
  private localImage: File | [] = [];
  private tmpObjectUrl: string | null = null;
  private signImagePath = "";
  private isFetching = true;
  private officeId = 0;
  private offices: Choice[] = [];

  @Watch("officeId")
  private officeDidChange() {
    // 事業所の変更があった場合、従業員の一覧をリセットする
    this.staffs = [];

    if (this.officeId === null) {
      return;
    }

    this.isFetching = true;
    this.postJsonBackground(
      window.chat_backend_url + "/api/chat/staffs/get",
      { agreement_id: this.innerAgreementId, office_id: this.officeId },
      (res) => {
        this.staffs = res.data.staffs
          .sort((a: Staff, b: Staff) => {
            // 職員は ふりがな降順 で表示する
            return a.furigana < b.furigana ? -1 : 1;
          })
          .map((staff: Staff) => {
            return {
              id: staff.id,
              ewellUserId: staff.ewell_user_id,
              staffName: staff.nickname,
              officeName: staff.office_name,
            };
          });

        this.isFetching = false;
      }
    );
  }

  created() {
    this.innerAgreementId = this.agreementId;
    this.selectedStaffList = this.currentAccounts
      .filter((account) => {
        // 自分自身は除外
        return account.id !== this.selfEwellUserId;
      })
      .map((account) => {
        return {
          id: account.staff_id,
          name: account.staff_name,
          ewellUserId: account.id,
        };
      });
    this.innerTalkTitle = this.talkTitle;
    this.officeId = this.loginUser.office_id;

    this.postJsonBackground(
      window.chat_backend_url + "/api/chat/offices/get",
      {
        agreement_id: this.agreementId,
      },
      (res) => {
        // 先頭は未所属とする
        const tmp = res.data.offices;
        tmp[0] = {
          text: "未所属",
          value: 0,
          other: 0,
          other_string: "",
        };

        this.offices = tmp;
      }
    );

    if (this.iconPath) {
      this.postJsonBackground(
        window.auth_backend_url + "/api/sign-image-path/get",
        {
          bucket_path: this.iconPath,
        },
        (res) => {
          this.signImagePath = res.data.image_path;
        }
      );
    }
  }

  destroy() {
    if (this.tmpObjectUrl) {
      URL.revokeObjectURL(this.tmpObjectUrl);
    }
  }

  private get SubmitButtonTitle() {
    return this.viewType === this.ViewType.create ? "作成" : "更新";
  }

  private get isEnabledSubmitButton() {
    // 一人でもトークルームの作成は可能
    return this.innerTalkTitle.length > 0 && this.innerAgreementId !== null;
  }

  private get isSystemRoom() {
    return this.roomType === RoomType.system;
  }

  /** AI自動作成通知用トークルーム判定 */
  private get IsAiReportChatRoom(): boolean {
    return this.fileType === FileType.aiReport;
  }

  private get UploadedImage() {
    const isArray = Array.isArray(this.localImage);
    if (isArray || !this.localImage) {
      return null;
    } else {
      if (this.tmpObjectUrl) {
        URL.revokeObjectURL(this.tmpObjectUrl);
      }
      this.tmpObjectUrl = URL.createObjectURL(this.localImage);
      return this.tmpObjectUrl;
    }
  }

  private get SelectedStaffIds() {
    return this.selectedStaffList.map((staff) => {
      return staff.id;
    });
  }
  
  private addStaff(staff: SelectedStaff) {
     const idx = this.selectedStaffList.findIndex((s) => {
      return s.id === staff.id
    });

    if (idx > -1) {
      // failsafe すでに選ばれている職員の追加はあり得ない
      return;
    } else {
      this.selectedStaffList.push(staff);
    }

    const remIdx = this.removedStaffs.findIndex((remS) => {
      return remS.id === staff.id;
    });

    if (remIdx > -1) {
      // 削除予定にいる職員を追加 -> 前と変化なし
      this.removedStaffs.splice(remIdx, 1);
    } else {
      // 削除予定にいない職員を追加 -> 追加予定に追加しておく
      this.addedStaffs.push(staff);
    }
  }
  
  private removeStaff(staff: SelectedStaff) {
    const idx = this.selectedStaffList.findIndex((s) => {
      return s.id === staff.id;
    });

    if (idx > -1) {
      this.selectedStaffList.splice(idx, 1);
    } else {
      // failsafe 選ばれていない職員の削除はあり得ない
      return;
    }

    const addIdx = this.addedStaffs.findIndex((addS) => {
      return addS.id === staff.id;
    });

    if (addIdx > -1) {
      // 追加予定にいる職員を削除 -> 前と変化なし
      this.addedStaffs.splice(addIdx, 1);
    } else {
      // 追加予定にいない職員を削除 -> 削除予定に追加しておく
      this.removedStaffs.push(staff);
    }
  }

  private async openExitDialog() {
    if (
      !(await this.$openConfirm(
        "退出すると、トークルームが見えなくなります\nよろしいですか？"
      ))
    ) {
      return;
    }

    this.exit();
  }

  private didClickChangeImageButton() {
    const input = document.getElementById("app-chat-room-editor-image-input");

    if (input) {
      input.click();
    }
  }

  private selectRoomImage(file?: File) {
    if (!file) return;

    if (file.size > 1e7) {
      this.localImage = [];
      this.$openAlert("ファイルサイズが10MBを超えています");
    }
  }

  private async saveRoom() {
    // 差分チェック
    if (this.viewType === this.ViewType.edit) {
      const diff = this.checkDiff();

      // トーク名の変更・職員の追加・職員の削除のいずれかがあった場合、確認ダイアログを表示する
      if (
        diff.hasTitleDiff ||
        diff.hasImageDiff ||
        diff.addStaffNames.length > 0 ||
        diff.removeStaffNames.length > 0
      ) {
        let line = 0;
        let dialogText = "以下の変更が行われます\n\n";
        if (diff.hasTitleDiff) {
          dialogText += `トーク名が ${this.innerTalkTitle} に変更されます`;
          line++;
        }

        if (diff.hasImageDiff) {
          dialogText += "トーク画像が変更されます";
          line++;
        }

        for (const addStaffName of diff.addStaffNames) {
          // 最大表示数は5件
          if (line > 4) {
            break;
          }

          if (line > 0) {
            dialogText += "\n";
          }

          dialogText += `${addStaffName} さんが追加されます`;
          line++;
        }

        for (const removeStaffName of diff.removeStaffNames) {
          if (line > 4) {
            break;
          }

          if (line > 0) {
            dialogText += "\n";
          }

          dialogText += `${removeStaffName} さんが削除されます`;
          line++;
        }

        const changedItemsCount =
          (diff.hasTitleDiff ? 1 : 0) +
          diff.addStaffNames.length +
          diff.removeStaffNames.length;
        if (changedItemsCount - line > 0) {
          dialogText += `\n\n他${changedItemsCount - line}件の変更があります`;
        }

        if (!(await this.$openConfirm(dialogText))) {
          return;
        }
      }
    }

    this.submit();
  }

  // ルームの差分チェック
  // return: ルームタイトル差分があるかどうか、追加されたスタッフ名、削除されたスタッフ名のタプル
  private checkDiff(): {
    hasTitleDiff: boolean;
    hasImageDiff: boolean;
    addStaffNames: string[];
    removeStaffNames: string[];
  } {
    const hasTitleDiff = this.talkTitle !== this.innerTalkTitle;

    const isArray = Array.isArray(this.localImage);
    // 配列（空）のまま or undefined で画像未設定と判断
    const hasImageDiff = !(isArray || !this.localImage);
    const addStaffNames = this.addedStaffs.map((staff) => { return staff.name });
    const removeStaffNames = this.removedStaffs.map((staff) => { return staff.name });

    return { hasTitleDiff, hasImageDiff, addStaffNames, removeStaffNames };
  }

  @Emit()
  private submit() {
    const isArray = Array.isArray(this.localImage);

    const data = {
      isCreate: this.viewType === this.ViewType.create,
      title: this.innerTalkTitle,
      staffList: [
        {
          id: this.selfStaffId,
          name: this.selfNickname,
          ewellUserId: this.selfEwellUserId,
        },
        ...this.selectedStaffList,
      ],
      agreementId: this.innerAgreementId,
    } as { [key: string]: unknown };

    if (isArray || !this.localImage) {
      // 配列（空）のまま or undefined で画像未設定と判断

      if (this.iconPath) {
        // 画像登録済みの場合は引き継ぎ
        data.imagePath = this.iconPath;
      }
    } else {
      data.image = this.localImage;
    }

    return data;
  }

  @Emit()
  private exit() {
    return;
  }
}
